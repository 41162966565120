<template>
  <div>
    <div class="flexRow justify-content-end" v-if="matchJurisdiction('ProjectLogType/add')">
      <el-button type="primary" @click="openBox(1)">新建日志类型</el-button>
    </div>
    <div class="marginTop flexRow align-item-center flexWrap" v-if="list.length">
      <div class="listCell flexRow align-item-center justify-content-between" v-for="(item,index) in list" :key="index">
        <span>{{item.name}}</span>
        <div>
          <el-button v-if="matchJurisdiction('ProjectLogType/edit')" circle type="primary" icon="el-icon-edit" @click="openBox(2,index)"></el-button>
          <el-button v-if="matchJurisdiction('ProjectLogType/del')" circle type="danger" icon="el-icon-delete" @click="delItem(item.id)"></el-button>
        </div>
      </div>
    </div>
    <div class="none" v-else>暂无类型</div>
    <el-dialog
        title="添加日志类型"
        :visible.sync="showBox"
        width="30%">
      <el-form ref="form" label-width="80px">
        <el-form-item label="类型名称">
          <el-input v-model="name" placeholder="请输入类型名称" clearable></el-input>
        </el-form-item>
        <div class="marginTop flexRow justify-content-end">
          <el-button type="primary" @click="subMsg">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "LogTag",
  data(){
    return{
      list:[],
      showBox:false,
      name:'',
    }
  },
  created(){
    this.$setMenuActive('logCategory')
  },
  mounted(){
    this.getList()
  },
  methods:{
    //获取列表
    async getList(){
      let res = await this.mainFun('ProjectLogType/getList');
      let data = res.data.data;
      this.list = data;
    },
    //打开新建or编辑弹窗
    openBox(type,index){
      if(type === 1){
        this.eid = '';
        this.name = '';
      }else{
        let item = this.list[index];
        this.eid = item.id;
        this.name = item.name;
      }
      this.showBox = true;
    },
    //提交信息
    async subMsg(){
      if(this.name === ''){
        this.$message.warning('请输入日志类型');
        return;
      }
      let params = {
        name:this.name
      }
      let url = 'ProjectLogType/'
      if(this.eid){
        params.id = this.eid;
        url += 'edit'
      }else{
        url += 'add'
      }
      let res = await this.mainFun(url,params);
      if(res.data.code == 1){
        this.$message.success(res.data.message);
        let p = 1;
        if(params.id){
          p = this.p;
        }
        await this.getList()
        this.showBox = false;
      }else{
        this.$message.error(res.data.message);
      }
    },
    //删除当前信息
    delItem(id){
      this.$confirm('是否确认删除当前信息','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning',
      }).then(async ()=>{
        let res = await this.mainFun('ProjectLogType/del',{id});
        if(res.data.code === 1){
          this.$message.success(res.data.message);
          await this.getList()
        }
      }).catch(()=>{})
    },
  }
}
</script>

<style scoped>
.listCell{
  padding:15px;
  width:240px;
  box-sizing: border-box;
  border:2px dashed #329DFF;
  border-radius: 5px;
  font-size:16px;
  color:#329DFF;
  margin:0 15px 15px 0;
}
.none{
  text-align: center;
  color:#999;
  margin-top:20%;
}
</style>